/* canvas {
    background: white;
    color: #000 !important;
    width: 100%;
    height: 100%;
    display: block;
  }
  
  .chart-container {
    padding: 1px;
  }
  
  svg {
    display: inline-block;
    vertical-align: middle;
  } */


/* map css  */

svg .polygon {
  stroke: black;
  stroke-width: .6px;

  cursor: pointer;
}

div.tooltip {
  padding: 10px;
  position: absolute;
  display: inline-block;
  text-align: center;
  background-color: rgba(0, 0, 0, .9);
  border-radius: 10px;
  pointer-events: none;
  color: white;
  font-size: 0.8rem;
}

.legendTitle {
  font-weight: bold;
}


.map {
  /* background-color:red; */
  height: 100%;
  width: 100%;
  display: flex;
}

/* */
.map_title {
  /* background-color:coral; */
  width: 60%;
  height: 10%;
  display: flex;
  justify-content: center;
  font-weight: 100;
  margin: 0px 200px;
  margin-top: 20px;

}

.map_tooltip {
  /* background-color:grey; */
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.map_req {
  /* background-color:yellow; */
  /* display: flex; */
  height: 100%;
  width: 0px;
  margin: 10px;
  z-index: 2;
  position: relative;
}

.map_req_button {
  /* background-color:blue; */
  margin: 20px;
  margin-top: 50px;
  margin-left: -180px;
  height: 50px;
  width: 200px;
}


.toggle_button {
  /* margin-left: -400px; */
  padding: 1px 1px;
  font-size: 0.06rem;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #FFFFFF !important;
  border-color: #AF5907 !important;

}

.req_button {
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #AF5907 !important;
  border-color: #AF5907 !important;
}

.req_button_light {
  background-color: #AF590780 !important;
  border-color: #AF590780 !important;
}


.map_req_text {
  margin: 20px;
  margin-top: 350px;
  margin-left: -150px;
  height: 50px;
  width: 150px;
  font-size: 0.6rem;
  font-weight: bold;
}

.back_button {
  width: 30px;
  height: 30px;
  padding: 1px 1px;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #FFFFFF !important;
  border-color: #AF5907 !important;
}

.req_button {
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #AF5907 !important;
  border-color: #AF5907 !important;
}

.req_button_light {
  background-color: #AF590780 !important;
  border-color: #AF590780 !important;
}

.map_req_legend {
  /* background-color:red; */
  height: 40%;
  width: 100%;
}

.map_req_text {
  /* background-color:coral; */
  /* transform: translate(-100,-50); */
  height: 20%;
}

g.cell text.label,
g.cell tspan {
  font-size: 0.75rem;
  font-weight: 600;
}

rect {
  width: 10px;
  height: 10px;
  display: inline-block;
}


@media (max-width: 790px) {
  .map_req_toggle {
    /* background-color:blue; */
    height: 20%;
    width: 100%;
    margin-left: 5px;
    margin-bottom: -60px;
  }

  .map_req {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;
    justify-content: flex-end;
    margin-left: 30px;
    margin-top: 50px;
  }

  .map_req_text {
    /* background-color:coral; */
    height: 20px;
  }

  .map_title {
    width: 380px;
    height: 10%;
    font-weight: 100;
    margin-left: 5px;
  }

  .map {
    /* background-color:red; */
    margin-left: 20px;
    display: flex;
  }

}



@media (max-width: 480px) {

  .map_req_button {
    /* background-color:blue; */
    /* display: flex; */
    margin: 20px;
    margin-left: -180px;
    height: 700px;
    width: 150px;
  }


  .map_req_text {
    /* background-color:blue; */
    /* display: flex; */
    margin: 20px;
    margin-left: -180px;
    margin-top: -20px;
    height: 12500px;
    width: 250px;
  }

  .req_button {
    margin: 5px;
    background-color: #AF5907 !important;
    border-color: #AF5907 !important;
  }

  .req_button_light {
    background-color: #AF590780 !important;
    border-color: #AF590780 !important;
  }

  .map_req {
    height: 100%;
    width: 0%;
    margin-left: 20px;
  }

  .map {
    /* background-color:red; */
    /* height: 50vh; */
    display: flex;
  }

}

.pieContainer {
  width: "20%";
  height: "20%";
  position: "absolute";
  transform: "translate(-20%, -20%)";
}

table {
  border-collapse: collapse;
  max-width: 900px;
  width: 100%;
  margin: 2rem auto;
}

ul#myTab.nav.nav-tabs.d-flex {
  height: 75%;
  margin: 0%;
  padding: 0%;
}

.nav-tabs .nav-item-right {
  color: black;
  width: 40%;
}

.radius1 {
  width: 50%;
}

.radius3 {
  color: black;
  width: 50%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
  display: inline-block;
  background: #EFC69B;
  overflow: hidden;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-link {
  padding: 0px 19px 0px 19px;
}

.nav-tabs .nav-link {
  border: 1px solid #af5906;
  background: #fff;
  color: black;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  text-align: center;
  padding: 7px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.nav-tabs .nav-link.radius1 {
  color: black;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.nav-tabs .nav-link.radius3 {
  color: black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

/* .table-container {
  overflow: auto;
  max-width: 85.75rem;
  max-height: 50rem;
} */

/* .react-bootstrap-table.table-responsive th {
  position: sticky;
  top: 1px;
}

.table-container>thead {
  position: sticky !important;
  left: 0;
  z-index: 2;
} */

.table td,
th {
  border: 1px solid !important;
}

/* .table-bordered th {
  box-shadow: 0 1px #dee2e6, 0 -1px #dee2e6;
} */

table caption {
  /* background-color: rgb(194, 193, 193); */
  padding: 0%;
  margin: 0%;
  caption-side: top;
}

span.caret {
  color: black !important;
}

/* .react-bootstrap-table.table-responsive tbody {
  display: block;
  height: 500px;
  overflow-y: scroll;
  width: auto;
}

.react-bootstrap-table.table-responsive thead {
  display: table;
  width: 100%;
  table-layout: auto;
}

.react-bootstrap-table.table-responsive tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */

/* .table-container table>tbody>tr>td:nth-child(1),
.table-container table>thead>tr:nth-child(1)>th:nth-child(1) {
  background-color: #dfdfdf;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
} */

.table-responsive {
  overflow: scroll;
  max-width: 93.75rem;
  max-height: 50rem;
}

.table-bordered th {
  position: sticky;
  top: 0px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.horizontal-slider {
  width: 97%;
  height: 10vh;
  margin: auto;
}

.disabled {
  width: 97%;
  opacity: 0.2;
}

.example-thumb {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  background-color: white;
  color: black;
  cursor: pointer;
  border: 2px solid gray;
  box-sizing: border-box;
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-0 {
  background: #30A054;
}


.example-track.example-track-1 {
  background: #ffff00;
}

.example-track.example-track-2 {
  background: #ff0000;
}

.horizontal-slider .example-track {
  height: 10px;
}

.horizontal-slider .example-thumb {
  width: 40px;
  height: 38px;
  line-height: 28px;
}

.example-mark {
  width: 8px;
  height: 8px;
  border: 2px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0px calc(19px);
  bottom: calc(50% - 6px);
}

.example-mark.example-mark-active {
  border: 2px solid #00d4ff;
  background-color: rgb(255, 255, 255);
}

.example-mark.example-mark-completed {
  border: 2px solid rgb(255, 255, 255);
  background-color: #00d4ff;
}

.download-button {
  color: #1E3A8A;
  font-weight: 600;
  border-color: #1E3A8A;
  border-width: 4px;
  border-radius: 0.5rem;
}

.blockTable {
  width: 90% !important;
}

.rdt_TableHeader {
  text-align: center;
  font-weight: bold !important;
  font-size: 1.25rem;
  background: none !important;
}